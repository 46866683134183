import {useParams} from "react-router";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {
    attachmentsAddress,
    backendAddress,
    deleteR,
    downloadAttachmentAddress,
    get,
    getErrorMessage,
    PATH_ADD_PRODUCT_ATTACHMENT,
    PATH_PRODUCT_SET_NEW,
    PATH_REMOVE_PRODUCT_ATTACHMENT,
    PATH_RENAME_ATTACHMENTS,
    PATH_SET_PRODUCT_ATTACHMENT_ORDER,
    PATH_VIEW_PRODUCTS_BY_CATALOG_NUMBER,
    post
} from "../../../common/backend";
import 'antd/es/modal/style';
import 'antd/es/slider/style';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    Paper,
    Select,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableRow,
    TextField
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import {Add, ArrowDownward, ArrowUpward, Edit, Remove, Save, Send} from "@mui/icons-material";
import {Upload} from "antd";
import ImgCrop from "bright-image-crop";
import '../../../ant-modal.css';
import MenuItem from "@mui/material/MenuItem";
import {DropzoneDialog} from "mui-file-dropzone";
import {useLoader} from "../../../common/Loader";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import axios from "axios";
import {toast} from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {getAPIKey} from "../../../common/auth/AuthContext";
import {clone, isEmpty} from "lodash";
import {convertJpgToPng, getAdminPageTitle, swapArrayLocs} from "../../../common/commons";
import SmallLoader from "../../../common/SmallLoader";

const ProductPage = () => {
    const sku = useParams().sku
    document.title = getAdminPageTitle(sku)
    const [attachments, setAttachments] = useState(null);
    const [newTill, setNewTill] = useState('');

    const {setLoading} = useLoader()

    const aspectList = [
        ['1:1', 1, 1],
        ['1:2', 1 / 2, 2],
        ['3:2', 3 / 2, 2 / 3],
        ['4:3', 4 / 3, 3 / 4],
        ['5:4', 5 / 4, 4 / 5],
        ['16:9', 16 / 9, 9 / 16]
    ]

    const [aspect, setAspect] = useState(aspectList[2]);
    const [aspectReversed, setAspectReversed] = useState(false);
    const [mirror, setMirror] = useState(false)
    //Название нового приложения. Может повторятся
    const [attachmentName, setAttachmentName] = useState('');
    //Названия схем повторятся не могут
    const [newDiagramName, setNewDiagramName] = useState('');

    const [dropzoneOpen, setDropzoneOpen] = useState(false);
    //Файл для открытия редактора фото
    const [newImage, setNewImage] = useState(/** @type Blob */ null);
    const [replaceId, setReplaceId] = useState(null)

    const [attachmentToRemove, setAttachmentToRemove] = useState(null);

    const [recommended, setRecommended] = useState('')

    const [uploading, setUploading] = useState([])
    const uploadingRef = useRef([])

    const addUploading = (name) => {
        const newUploading = clone(uploadingRef.current)
        newUploading.push(name)
        setUploading(newUploading)
        uploadingRef.current = newUploading
    }

    const removeUploading = (name) => {
        const newUploading = clone(uploadingRef.current)
        newUploading.splice(newUploading.indexOf(name), 1)
        setUploading(newUploading)
        uploadingRef.current = newUploading
    }

    // Указываем в запросе на добавление фото, чтобы можно было понять, что добавляем именно схему
    const newDiagram = useRef(false);
    // Сообщение об ошибке при открытии страницы
    const errMessage = useRef(null);

    document.title = `Rimet|Product ${sku}`

    const loadData = () => {
        get(PATH_VIEW_PRODUCTS_BY_CATALOG_NUMBER + sku, res => {
            const data = Object.values(res.data.data)[0]
            setAttachments(data[0].attachments);
            setNewTill(data[0].new);
            setRecommended(data[0].recommended.join(','))
        }, res => errMessage.current = (res.response || res).data.error.message)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (attachments === null && errMessage.current === null) {
            loadData()
        }
    })

    //Вызывает после выбора файла
    const submitAttachmentFile = (file) => {
        setDropzoneOpen(false);
        switch (file[0].name.split('.').pop()) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                if (newDiagram.current) {
                    uploadAttachment(file[0])
                } else {
                    if (file[0].name.split('.').pop() !== 'png') {
                        var reader = new FileReader();
                        reader.readAsDataURL(file[0]);
                        reader.onloadend = function () {
                            var base64data = reader.result;
                            convertJpgToPng(base64data, (it) => {
                                Object.assign(file[0], it)
                                setNewImage(file[0])
                                console.log("new image")
                            })
                        }
                    } else {
                        setNewImage(file[0])
                    }
                }
                break;
            case 'mp4':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
            case 'pdf':
            case 'gif':
                uploadAttachment(file[0])
                break;
            default:
                toast.error('Unknown format')
                break;
        }
    }

    // Загрузка приложения (фото, видео, гиф)
    const uploadAttachment = (file) => {
        addUploading(file.name)
        const formData = new FormData()
        formData.append('attachment', file)
        const name = newDiagram.current ? newDiagramName : attachmentName
        if (name) formData.append('name', name)
        if (newDiagram.current) {
            formData.append('diagram', '1')
        }
        if (replaceId) {
            formData.append('replace_id', replaceId.toString())
        }
        axios.post(backendAddress + PATH_ADD_PRODUCT_ATTACHMENT.replace('{cn}', sku), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getAPIKey()
            },
            withCredentials: false
        }).then(() => {
            toast.success('Attachment added!');
        }).catch(e => {
            toast.error(`Error: ${getErrorMessage(e)}`);
        }).finally(() => {
            loadData()
            removeUploading(file.name)
        })
        //при редактировании фото пропадает скролл
        document.body.style.removeProperty('overflow')
        setNewImage(null)
        setReplaceId(null)
    };

    //Изменение порядка фото. Порядок остальных файлов изменить нельзя
    const swapUp = (img) => {
        const arr = clone(attachments);
        swapArrayLocs(arr, arr.indexOf(img), arr.indexOf(img) - 1)
        setAttachments(arr)
    }
    const swapDown = (img) => {
        const arr = clone(attachments);
        swapArrayLocs(arr, arr.indexOf(img), arr.indexOf(img) + 1);
        setAttachments(arr);
    }

    const removeAttachment = () => {
        setLoading(true);
        deleteR(PATH_REMOVE_PRODUCT_ATTACHMENT.replace('{cn}', sku).replace('{id}', attachmentToRemove.id), () => {
            setAttachments(attachments.filter(it => it.id !== attachmentToRemove.id));
            setAttachmentToRemove(null);
            setLoading(false);
            toast.success(`Attachment deleted!`);
        }, res => {
            setAttachmentToRemove(null);
            setLoading(false);
            toast.error(getErrorMessage(res));
        })
    }

    const save = () => {
        setLoading(true)

        const setOrder = post(PATH_SET_PRODUCT_ATTACHMENT_ORDER.replace('{cn}', sku), () => {
            toast.success('Attachment order saved');
        }, res => {
            toast.error(getErrorMessage(res))
        }, {
            order: attachments.map(it => it.id),
            recommended: recommended.split(',').filter(it => !isEmpty(it))
        })
        const renameAttachments = post(PATH_RENAME_ATTACHMENTS, () => {
            toast.success('Attachments renamed')
        }, res => toast.error(getErrorMessage(res)), {data: Object.fromEntries(Object.values(attachments).map(it => [it.id, it.name]))})
        Promise.all([setOrder, renameAttachments]).finally(() => setLoading(false))
    }

    // Установка времени актуальности
    const setNew = (duration) => {
        setLoading(true)
        post(PATH_PRODUCT_SET_NEW,
            res => {
                setNewTill(res.data.data)
                if (res.data.data == null) {
                    toast.success('Product isn`t new anymore');
                    return
                }
                toast.success('Product marked as new till ' + (new Date(res.data.data)).toISOString().split('T')[0]);
            },
            res => toast.error('Error: ' + res.data.error.message), {
                'duration': duration, //Только количество месяцев
                'catalog_numbers': [sku]
            }).finally(() => setLoading(false))
    };

    const getAttachmentElement = (attachment) => {
        switch (attachment.type) {
            case 'video':
                return <video width='310' height='200' preload='metadata' controls>
                    <source src={`${attachmentsAddress}${attachment.id}/full.mp4`} type="video/mp4"/>
                    {'Your browser does not support HTML5 video.'}
                </video>
            case 'file':
                return <a rel='noreferrer' target='_blank'
                          href={`${attachmentsAddress}${attachment.id}/full.${attachment.extension}`}>
                    <img alt='' src={`${attachmentsAddress}${attachment.id}/catalog.jpg`}/>
                </a>
            case 'img':
                return <img alt='' src={`${attachmentsAddress}${attachment.id}/full.${attachment.extension}`}/>
            case 'diagram':
                return <img alt='' src={`${attachmentsAddress}${attachment.id}/full.${attachment.extension}`}/>
            case 'gif':
                return <img alt='' src={`${attachmentsAddress}${attachment.id}/full.gif`}/>
            default:
                return ''
        }
    }

    if (errMessage.current !== null) {
        return <Card sx={{maxWidth: '33%', margin: 'auto'}}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Error
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {errMessage.current}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" component={Link} to="/admin/product">Product list</Button>
            </CardActions>
        </Card>
    }

    const edit = it => {
        setLoading(true)
        fetch(downloadAttachmentAddress + `?name=file&extension=jpg&id=${it.id}`)
            .then(res => res.blob()).then(file => {
            setReplaceId(it.id)
            setNewImage(file)
        }).finally(() => setLoading(false))
    }

    const mirrorImage = () => {
        const img = new Image();
        img.src = URL.createObjectURL(newImage)
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw image onto canvas
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            // Flip image horizontally
            ctx.translate(canvas.width, 0);
            ctx.scale(-1, 1);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert canvas to image blob
            canvas.toBlob((blob) => {
                setNewImage(blob)
            }, 'image/png')
        };
        setMirror(!mirror)
    }

    if (!attachments) {
        return ''
    }

    return [
        <Dialog
            key='remove-image-dialog'
            open={attachmentToRemove !== null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Delete ${attachmentToRemove && attachmentToRemove.type === 'diagram' ? 'Diagram' : 'Attachment'} ${attachmentToRemove && attachmentToRemove.name}?`}
            </DialogTitle>
            <DialogContent>
                <Typography color='red' variant="h4">This action cannot be undone</Typography>
                <DialogContentText id="alert-dialog-description">
                    {attachmentToRemove && getAttachmentElement(attachmentToRemove)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setAttachmentToRemove(null)
                }}>No</Button>
                <Button onClick={() => {
                    removeAttachment();
                }} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>,
        <DropzoneDialog key='dropzone-dialog' maxFileSize={104857600}
                        acceptedFiles={newDiagram.current ? ['image/jpeg', 'image/png', 'image/gif'] : ['image/jpeg', 'image/png', 'image/gif', '.doc', '.docx', '.xls', '.xlsx', '.pdf', '.mp4']}
                        onClose={() => setDropzoneOpen(false)}
            // onSave={submitAttachmentFile}
                        onDrop={submitAttachmentFile}
                        clearOnUnmount={true} filesLimit={1}
                        showPreviews={false}
                        dialogTitle={newDiagram.current ? 'Upload Diagram' : 'Upload Attachment'}
                        dropzoneText={`Drop New ${newDiagram.current ? 'Diagram' : 'File'} Here`}
                        open={dropzoneOpen}/>,
        <Fab hidden={newImage === null} key='crop_aspect_buttons' variant='extended' sx={{
            position: "fixed",
            top: (theme) => theme.spacing(20),
            right: (theme) => theme.spacing(21)
        }}>
            <FormGroup sx={{display: 'inline'}}>
                <Select
                    variant='standard'
                    labelId='aspect-label'
                    defaultValue='0'
                    label='Aspect'
                    onChange={e => setAspect(aspectList[e.target.value])}
                >
                    {aspectList.map((it, i) => <MenuItem key={`aspect-${i}`} value={i}>{it[0]}</MenuItem>)}
                </Select>
                <FormControlLabel sx={{marginLeft: '8px'}}
                                  control={<Checkbox
                                      onChange={() => setAspectReversed(!aspectReversed)}/>}
                                  label={'Reverse Aspect'}/>
            </FormGroup>
        </Fab>,
        <Fab hidden={newImage === null} key='crop_buttons' variant='extended' sx={{
            position: "fixed",
            top: (theme) => theme.spacing(27),
            right: (theme) => theme.spacing(21)
        }}>
            <FormGroup sx={{display: 'inline'}}>
                <FormControlLabel sx={{marginLeft: '8px'}}
                                  control={<Checkbox onChange={mirrorImage}/>} label={'Mirror'}/>
            </FormGroup>
        </Fab>,
        <ImgCrop key={"image-editor-" + aspect[0] + aspectReversed.toString()}
                 onModalCancel={() => {
                     setNewImage(null)
                     document.body.style.removeProperty('overflow')
                 }}
                 cropperProps={{
                     restrictPosition: false
                 }} newImage={newImage} onModalOk={uploadAttachment} aspect={aspect[aspectReversed ? 2 : 1]}
                 minZoom={0.1}
                 quality={1}
            // modalWidth={'960px'}
                 grid rotate>
            <Upload listType="picture-card"/>
        </ImgCrop>,
        <Box key='header' sx={{marginTop: '25px', width: '500px', height: '', marginLeft: 'auto', marginRight: 'auto'}}
             component={Paper}>
            <Typography sx={{display: 'inline-block', margin: '15px'}}>Marked as new
                till: {newTill || 'None'}</Typography>
            <FormControl sx={{minWidth: '120px', display: 'inline-block', float: 'right'}} variant="standard">
                <InputLabel id="mark-new-label">Mark New</InputLabel>
                <Select
                    labelId="mark-new-label"
                    label="Mark New"
                    onChange={e => setNew(e.target.value)}
                    value={-1}
                >
                    <MenuItem value={-1}>Duration</MenuItem>
                    <MenuItem value={0}>None</MenuItem>
                    <MenuItem value={6}>6 month</MenuItem>
                    <MenuItem value={12}>12 month</MenuItem>
                    <MenuItem value={18}>18 month</MenuItem>
                    <MenuItem value={24}>24 month</MenuItem>
                </Select>
            </FormControl>
        </Box>,
        <TableContainer
            key="attachments-container"
            sx={{marginTop: '25px', width: '500px', height: '', marginLeft: 'auto', marginRight: 'auto'}}
            component={Paper}>
            <Typography variant='h4' color='#1976d2' textAlign='center'>
                Images Videos Files
            </Typography>
            <Table>
                <TableBody key="attachments-body">
                    {attachments.filter(it => it.type !== 'diagram').map((it, i) =>
                        <TableRow key={`attachment-${i}`}>
                            <TableCell>
                                {(it.type === 'img' || it.type === 'gif') &&
                                    [
                                        <IconButton key='swap-up' onClick={() => swapUp(it)} disabled={i === 0}>
                                            <ArrowUpward/>
                                        </IconButton>,
                                        <IconButton key='swap-down' onClick={() => swapDown(it)}
                                                    disabled={i === attachments.filter(it => it.type === 'img' || it.type === 'gif').length - 1}>
                                            <ArrowDownward/>
                                        </IconButton>
                                    ]}
                            </TableCell>
                            <TableCell>
                                <Typography variant='h5' sx={{textAlign: 'center'}}>
                                    <TextField variant='standard' onChange={e => it.name = e.target.value}
                                               defaultValue={it.name}/>
                                </Typography>
                                <br/>
                                <div>
                                    {getAttachmentElement(it)}
                                </div>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    setAttachmentToRemove(it);
                                }}>
                                    <Remove/>
                                </IconButton>
                                <IconButton onClick={() => edit(it)}>
                                    <Edit/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                    {uploading.map(it =>
                        <TableRow key={`loader-${it}`}>
                            <TableCell/>
                            <TableCell>
                                {it}
                            </TableCell>
                            <TableCell>
                                <SmallLoader/>
                            </TableCell>
                        </TableRow>)}
                    <TableRow>
                        <TableCell variant='footer'>
                            <IconButton onClick={() => {
                                newDiagram.current = false;
                                setDropzoneOpen(true);
                            }}>
                                <Add color='primary' fontSize='large'/>
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            <FormGroup sx={{display: 'inline'}}>
                                <TextField
                                    defaultValue=''
                                    label='Name'
                                    onChange={e => setAttachmentName(e.target.value)}
                                />
                                <InputLabel id='aspect-label'>Image Aspect</InputLabel>
                                <Select
                                    labelId='aspect-label'
                                    defaultValue='0'
                                    label='Aspect'
                                    onChange={e => setAspect(aspectList[e.target.value])}
                                >
                                    {aspectList.map((it, i) => <MenuItem key={`aspect-${i}`}
                                                                         value={i}>{it[0]}</MenuItem>)}
                                </Select>
                                <FormControlLabel sx={{marginLeft: '8px'}}
                                                  control={<Checkbox
                                                      onChange={() => setAspectReversed(!aspectReversed)}/>}
                                                  label='Reverse Aspect'/>
                            </FormGroup>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>,
        <TableContainer key='docs-container'
                        sx={{marginTop: '25px', width: '500px', marginLeft: 'auto', marginRight: 'auto'}}
                        component={Paper}>
            <Typography variant='h4' color='#1976d2' textAlign='center'>
                Diagrams
            </Typography>
            <Table>
                <TableBody>
                    {attachments.filter(it => it.type === 'diagram').map(it => {
                        return <TableRow key={`diagram-${it.name}`}>
                            <TableCell/>
                            <TableCell sx={{textAlign: 'center'}}>
                                <TextField sx={{marginBottom: '10px'}} onChange={(e) => it.name = e.target.name}
                                           variant='standard' defaultValue={it.name}/>
                                <p>
                                    <img src={`${attachmentsAddress}${it.id}/full.${it.extension}`} alt=''/>
                                </p>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => setAttachmentToRemove(it)}><Remove/></IconButton>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align='left'>
                            <IconButton onClick={() => {
                                if (newDiagramName === '') {
                                    toast.error('No diagram name');
                                    return;
                                }
                                if (attachments.filter(it => it.type === 'diagram' && it.name.toLowerCase() === newDiagramName.toLowerCase()).length !== 0) {
                                    toast.error(`Diagram ${newDiagramName} exists`);
                                    return;
                                }
                                newDiagram.current = true;
                                setDropzoneOpen(true);
                            }}>
                                <Add fontSize='large' color='primary'/>
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            <TextField onChange={e => setNewDiagramName(e.target.value)} variant='outlined'
                                       placeholder='Name' defaultValue=''/>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>,
        <Paper key='recommended'
               sx={{marginTop: '25px', width: '500px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
            <Typography variant='h4' color='#1976d2' textAlign='center'>
                Recommended
            </Typography>
            <TextField value={recommended} onChange={e => setRecommended(e.target.value)}/>
        </Paper>,
        <Fab variant='extended' key='save-button' sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
        }} onClick={save} color="primary"><Save sx={{mr: 1}}/>Save</Fab>,
        <Fab variant='extended' key='goto-button'
             target='_blank' component={Link} to={`/${sku}`}
             sx={{
                 position: "fixed",
                 bottom: (theme) => theme.spacing(2),
                 right: (theme) => theme.spacing(2),
                 top: (theme) => theme.spacing(10)
             }} color="primary"><Send sx={{mr: 1}}/>Open</Fab>
    ];
}
export default ProductPage
