import Tooltip from "@mui/material/Tooltip";
import React from "react";
import axios from "axios";
import {attachmentsAddress, backendAddress, PATH_ORDER_DOWNLOAD} from "./backend";
import {getAPIKey} from "./auth/AuthContext";
import {isEmpty} from "lodash";
import Decimal from "decimal.js";

export const adminPageTitle = "Admin | Rimet"

export function convertJpgToPng(jpgUrl, callback) {
    var img = new Image();

    img.onload = async function () {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        // Convert the image to PNG format
        canvas.toBlob((blob) => {
            callback(blob)
        }, 'image/png')
    };

    img.src = jpgUrl;
}

export const getAdminPageTitle = (page = null) => {
    if (page === null) {
        return adminPageTitle
    }
    return `${adminPageTitle} | ${page}`
}

/**
 * Обернуть элемент в Tooltip-подсказку
 * @param text {string}
 * @param children
 * @return {JSX.Element}
 */
export const wrapTooltip = (text, children) => {
    //Свойство float должно совпадать у элемента span и дочерних элементов для корректного тображения Tooltip
    const style = {}
    if ('props' in children && 'sx' in children.props) {
        const sx = children.props.sx
        if ('float' in sx) {
            style.float = sx.float
        }
    }
    return <Tooltip title={text} placement='top'><span style={style}>{children}</span></Tooltip>
}
/**
 * Разница между числами в процентах
 * @param a {number}
 * @param b {number}
 * @return {number}
 */
export const numbersDifference = (a, b) => 100 * Math.abs((a - b) / ((a + b) / 2));
/**
 * Разница между числами в процентах
 * Для дробных чисел
 * @param a {number|string}
 * @param b {number|string}
 * @return {Decimal}
 */
export const numbersDifferenceFloat = (a, b) => {
    if (!a || !b) {
        return new Decimal('-1')
    }
    const first = new Decimal(a.toString())
    const second = new Decimal(b.toString())
    return Decimal.abs(first.minus(second).div(first.plus(second).div(new Decimal('2')))).mul(new Decimal('100'))
}
/**
 * Скачать файл заказа
 * @param order {{id,created_at}}
 * @param forAdmin {boolean}
 * @return {Promise<AxiosResponse<any>>}
 */
export const downloadOrder = (order, forAdmin = true) => {
    return axios({
        url: backendAddress + PATH_ORDER_DOWNLOAD + order.id + '?admin=' + (forAdmin ? '1' : '0'),
        method: 'GET',
        responseType: 'blob',
        withCredentials: false,
        headers: {Authorization: 'Bearer ' + getAPIKey(), accept: '*/*'}
    }).then(response => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        let filename = response.headers['content-disposition']
        const index = filename.indexOf('filename="');
        filename = filename.substring(index + 'filename="'.length);
        filename = filename.slice(0, -1);
        if (filename.includes('"')) {
            filename = filename.slice(0, filename.indexOf('"') + 1)
        }
        link.download = decodeURIComponent(filename)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}
/**
 * Установить фильтры для категории ручек
 * @param val {{}}
 * @return void
 */
export const setCabinetHandleFilters = (val = {}) => {
    val = Object.fromEntries(Object.entries(val).map(([filter, values]) => [filter, Object.entries(values).filter(([_, selected]) => selected).map(([name, _]) => name)]).filter(([__, values]) => !isEmpty(values)))
    localStorage.setItem('ch-f', JSON.stringify(val))
}
/**
 * @return {{}}
 */
export const getCabinetHandleFilters = () => JSON.parse(localStorage.getItem('ch-f') ?? '{}')
/**
 * Адрес первого фото товара или заглушка
 * @param item {{attachments:{type:string}[]}}
 * @return string
 */
export const getItemImage = item => {
    const attachment = item.attachments.find(it => it.type === 'gif' || it.type === 'img')
    if (attachment !== undefined) {
        return `${attachmentsAddress}${attachment.id}/catalog.jpg`
    } else {
        return '/images/no-image.jpg'
    }
}
/**
 * Адрес полного размера приложения (фото, видео, гиф)
 * @param attachment {{id,extension:string,type:string,path:string}}
 * @return string|null
 */
export const getAttachmentAddress = attachment => {
    switch (attachment.type) {
        case 'video':
            return `${attachmentsAddress}${attachment.id}/full.mp4`
        case 'img':
            return `${attachmentsAddress}${attachment.id}/full.${attachment.extension}`
        case 'diagram':
            return `${attachmentsAddress}${attachment.id}/full.${attachment.extension}`
        case 'gif':
            return `${attachmentsAddress}${attachment.id}/full.gif`
        case 'iframe':
            return attachment.path
        default:
            return null
    }
}

export const reorder = <T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

//Последние посещенные категории в заводе 1 и 2
export const setLastCategory = (factory, link) => localStorage.setItem('last-category-' + factory, link)
export const getLastCategory = (factory) => localStorage.getItem('last-category-' + factory)
export const swapArrayLocs = (arr, index1, index2) => [arr[index1], arr[index2]] = [arr[index2], arr[index1]]
