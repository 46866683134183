import {createContext, useContext, useState} from "react";

const CurrentCategoryContext = createContext(null);

const useCurrentCategory = () => {
    const context = useContext(CurrentCategoryContext)
    if (!context) throw new Error('useCurrentCategory must be used inside CurrentCategoryContext')
    return context
}

const CurrentCategoryProvider = ({children}) => {
    /** @type array|Object [categoryId]|{categoryId=>page} */
    const [current, setCurrent] = useState([])
    return <CurrentCategoryContext.Provider children={children} value={{current, setCurrent}}/>
}
export default CurrentCategoryProvider
export {useCurrentCategory}
